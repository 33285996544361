<template>
  <div class="wcpt-session-list" style="justify-content:flex-start; margin:0">

    <ion-list class="wcpt-session-list" v-if="sessions">
      <div v-for="session in sessions" :key="session.id">
        <a :href="session.session_type_text != 'Break' ? '#/app/sessions/' + session.id : ''"
           v-if="!session.isHeader"
           :class="{'wcpt-session-item' : session.session_type_text != 'Break', 'wcpt-session-item-break' :session.session_type_text == 'Break'}"
           :style="{border:'4px solid ' +  session.color} ">

          <!--img class="picture" src="img/avatar.png" ng-if="!session.chair[0] || !session.chair[0].vitae_picture">
          <img class="picture" ng-src="https://fileserver-188ab.kxcdn.com/file/{{session.chair[0].vitae_picture.split('/')[4]}}" ng-if="session.chair[0] && session.chair[0].vitae_picture"-->
          <div class="head">
            <div class="type">{{ session.session_type_text }}</div>
            <div class="type-icons" v-if="session.session_type_text != 'Industriesymposium'">
              <font-awesome-icon :icon="['fas', 'camera-movie']" class="type-icon" v-if="session.is_stream || session.is_recording"/>
              <!--font-awesome-icon :icon="['fas', 'file-pdf']" class="type-icon"/-->
            </div>
            <div class="type" v-if="session.session_type_text != 'Break' && withTime">
                <span style="color:gray; font-size: 12px">
          {{ moment(session.start_time).format('dddd HH:mm') }} -
                  {{ moment(session.end_time).format('HH:mm') }} <span class="" translate="UHR"></span></span>
            </div>
            <div class="time" vif="session.session_type_text == 'Break'"><span style="color:gray; font-size: 12px">
          {{ moment(session.start_time).format('dddd HH:mm') }} -
                  {{ moment(session.end_time).format('HH:mm') }} <span class="" translate="UHR"></span></span>
            </div>
            <!--div class="code" ng-if="session.code">[{{session.code}}]</div-->
          </div>
          <div class="content">
            <div class="title">{{ session.title }}</div>
            <div class="chair" v-if="session.chair && session.chair.length > 0"><strong>Vorsitz:</strong> <span
                v-for="chair in session.chair" :key="chair.id">{{ chair.display_name }}</span></div>
            <div class="speaker" v-if="session.speaker && session.speakers.length > 0"><strong>Referierende:</strong> <span
                v-for="speaker in session.speakers" :key="speaker.id">{{ speaker.first_name }} {{ speaker.last_name }}</span></div>
          </div>
          <div class="indicator" v-if="activeSessions.indexOf(session.id) != -1">[ LIVE ]</div>
          <!--div class="indicator" style="color:black;font-size:2.4rem" v-if="isOnDemandAvailable(session)"><i
              class="icon ion-ios-videocam"></i></div-->
          <div class="shape" :style="{background:session.color}">
            <div class="room" :style="{color:session.font_color}"><p class="channel">{{ session.location.name }}</p>
            </div>
          </div>
        </a>


        <div v-if="session.isHeader" class="item item-divider" style="width:100vw; padding-left:20px">
          <h2> {{ moment(session.start_time).format('HH:mm') }} </h2>
        </div>

      </div>
    </ion-list>

  </div>
</template>
<script>
import moment from 'moment';

moment.locale("de");
import {alertController, IonList} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from "vuex";

const config = require('@/config');


export default {
  data() {
    return {
      freeSymps: ['da7ad0c0-3ed1-4500-1345-010000000025', 'da7ad0c0-3ed1-4500-1345-010000000044', 'da7ad0c0-3ed1-4500-1345-010000000027', 'da7ad0c0-3ed1-4500-1345-010000000065','da7ad0c0-3ed1-4500-1345-010000000057','da7ad0c0-3ed1-4500-1345-010000000026']
    }
  },
  components: {
    IonList
  },
  props: ['sessions', 'withTime'],
  created() {
    this.moment = moment;
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sessions', ['activeSessions']),
  },
  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    isAllowed() {
      if (this.user && this.user.extra && this.user.extra[config.conference]) {
        return true
      } else {
        return true
      }
    },

    async raiseNotAllowed() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Info',
            message: 'Ihre Registrierung enthält keinen Zugriff auf diese Ressource',
            buttons: [
              {
                text: 'OK',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
            ],
          });
      return alert.present();
    },
    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        //let disclaimer = session.disclaimer.replaceAll('&lt;br&gt;', '<br>');
        let disclaimer = session.disclaimer.replace(/&lt;br&gt;/g, '<br>');
        console.log('replaced disclaimer', disclaimer)

        if ((!this.user && this.freeSymps.indexOf(session.id) != -1) || this.user) {
          const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: 'Information',
                message: '<strong>' + disclaimer + '</strong>',
                buttons: [
                  {
                    text: 'Nein',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: blah => {
                      console.log('Confirm Cancel:', blah);
                      this.$router.push('/app/sessions/' + session.id);
                    },
                  },
                  {
                    text: 'Ja',
                    id: 'confirm-button',
                    handler: () => {

                      if (this.user) {
                        let trackData = {
                          user: this.user,
                          item: session,
                          event: 'share',
                          type: 'session',
                          itemId: session.id,
                        }
                        this.trackEvent(trackData)
                        this.uploadTrackingEvents();

                        this.trackWithLabel(session.title);
                        this.$router.push('/app/sessions/' + session.id);
                      } else {
                        this.showUserPrompt(session);
                      }


                    },
                  },
                ],
              });
          return alert.present();
        } else {
          const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: 'Keine Berechtigung',
                message: '<strong>Sie haben keine Berechtigung diesen Bereich zu sehen</strong>',
                buttons: [
                  {
                    text: 'OK',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: blah => {
                      console.log('Confirm Cancel:', blah);
                    },
                  },
                ],
              });
          return alert.present();
        }


      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    },

    async showUserPrompt(session) {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Information',
            message: '<strong>Bitte geben Sie Ihre Kontaktdaten ein</strong>',
            inputs: [
              {
                name: 'firstName',
                placeholder: 'Vorname',

              },
              {
                name: 'lastName',
                placeholder: 'Nachname',

              },
              {
                name: 'email',
                placeholder: 'Email',

              },
            ],
            buttons: [
              {
                text: 'Nein',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah);
                  this.$router.push('/app/sessions/' + session.id);
                },
              },
              {
                text: 'Ja',
                id: 'confirm-button',
                handler: (data) => {
                  console.log(data)
                  let trackData = {
                    user: data,
                    item: session,
                    event: 'share',
                    type: 'session',
                    itemId: session.id,
                  }
                  this.trackEvent(trackData)
                  this.uploadTrackingEvents();

                  this.trackWithLabel(session.title);
                  this.$router.push('/app/sessions/' + session.id);
                },
              },
            ],
          });
      return alert.present();

    }
  }

};
</script>
<style lang="scss">
.my-custom-class {
  input[type=text], input[type=password] {
    border-radius: 0;
    padding: 10px;
    background-color: #ffffff;
    border: 0.5px solid #c9c9c9;
    color: black;
  }
}
</style>
